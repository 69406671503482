<script setup lang="ts">
const props = defineProps<{
	label?: string
	dropdown?: boolean
	grid?: boolean
}>();
</script>

<template>
	<div
		class="data"
		:class="{
			'data--dropdown': props.dropdown === true,
			'data--grid': props.grid === true,
		}"
	>
		<div class="data__header" v-if="props.label">
			<span class="data__title">{{ props.label }}</span>
		</div>
		<div class="data__body">
			<slot />
		</div>
	</div>
</template>
